/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
  --ion-grid-width-xs: 100%;
  --ion-grid-width-sm: 100%;
  --ion-grid-width-md: 90%;
  --ion-grid-width-lg: 90%;
  --ion-grid-width-xl: 85%;
}

/* Ionic components */
ion-button {
  --box-shadow: none;
}

*:focus {outline:0;}

/* Typography */
h1, h2, h3, p, a, ion-label {
  font-family: Poppins, sans-serif;
  color: var(--ion-color-dark);
}

h1 {
  font-weight: 500;
  font-size: 56px !important;
}

h2 {
  font-size: 24px !important;
  line-height: 35px;
  font-weight: 500;
}

h3 {
  font-weight: 500;
  font-size: 20px;
}

p {
  font-size: 16px;
}

@media (max-width: 720px) {
  h1 {
    font-size: 45px !important;
  }
}

@media (max-width: 540px) {
  h1 {
    font-size: 35px !important;
  }
}

/* Shared classes */
.card-container {
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
}

.product-card-container {
  margin: 5px;
  padding: 20px;
  background-color: white;
  min-height: 59vh;

  ion-img {
    height: 400px;
    width: 100%;
    margin-top: 15px;
  }

  h3 {
    margin-top: 0;
  }
}

.error-message {
  color: var(--ion-color-danger)
}

.frame-modal {
  --width: 80%;
  --max-width: 850px;
}

.offer-modal {
  --width: 90%;
  --max-width: 1050px;
  --max-height: 80vh;
}

.comment-modal {
  --width: 95%;
  --max-width: 550px;
  --max-height: 490px;
}

.photo-album-modal {
  --width: 95%;
  --max-width: 550px;
  --height: 580px;
}

.alert-wrapper{
  max-width: unset !important;
  width: 400px !important;
}

@media (max-width: 540px) {

  .alert-wrapper{
    max-width: unset !important;
    width: 80% !important;
  }
}


.animated-card-container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  overflow: hidden;
  padding-top: 60px;

  img {
    position: absolute;
    top: 60px;
  }

  h2 {
    color: white;
    margin-top: 150px;
    transition: 0.5s;
    text-align: center;
  }

  button {
    position: absolute;
    bottom: -100px;
    z-index: -1;
    transition: 0.5s;
  }

  &:hover {
    h2 {
      color: white;
      margin: 40px 0 20px 0;
      transition: 0.5s;
    }
    button {
      position: relative;
      z-index: 1;
      bottom: 0;
      transition: 0.5s;
    }
  }
}

.bg-primary {
  background-color: var(--ion-color-primary);
}
.bg-secondary {
  background-color: var(--ion-color-secondary);
}
.bg-tertiary {
  background-color: var(--ion-color-tertiary);
}
