// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #e25e11;
  --ion-color-primary-rgb: 226,94,17;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #cb5816;
  --ion-color-primary-tint: #e56e29;

  --ion-color-secondary: #1f4778;
  --ion-color-secondary-rgb: 31,71,120;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #1b3e6a;
  --ion-color-secondary-tint: #355986;

  --ion-color-tertiary: #faa73c;
  --ion-color-tertiary-rgb: 250,167,60;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #dc9235;
  --ion-color-tertiary-tint: #ffb65a;

  --ion-color-success: #56cd86;
  --ion-color-success-rgb: 86,205,134;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #4cb476;
  --ion-color-success-tint: #67d292;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235,68,90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #002451;
  --ion-color-dark-rgb: 0,36,81;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #002047;
  --ion-color-dark-tint: #1a3a62;

  --ion-color-medium: #f4dfc5;
  --ion-color-medium-rgb: 244,223,197;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #d7c4ad;
  --ion-color-medium-tint: #feedd8;

  --ion-color-light: #f2eee8;
  --ion-color-light-rgb: 242,238,232;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d5d1cc;
  --ion-color-light-tint: #f3f0ea;
}

//@media (prefers-color-scheme: dark) {
//  /*
//   * Dark Colors
//   * -------------------------------------------
//   */
//
//  body {
//    --ion-color-primary: #e25e11;
//    --ion-color-primary-rgb: 226,94,17;
//    --ion-color-primary-contrast: #ffffff;
//    --ion-color-primary-contrast-rgb: 255,255,255;
//    --ion-color-primary-shade: #c7530f;
//    --ion-color-primary-tint: #e56e29;
//
//    --ion-color-secondary: #1f4778;
//    --ion-color-secondary-rgb: 31,71,120;
//    --ion-color-secondary-contrast: #ffffff;
//    --ion-color-secondary-contrast-rgb: 255,255,255;
//    --ion-color-secondary-shade: #1b3e6a;
//    --ion-color-secondary-tint: #355986;
//
//    --ion-color-tertiary: #faa63c;
//    --ion-color-tertiary-rgb: 250,166,60;
//    --ion-color-tertiary-contrast: #000000;
//    --ion-color-tertiary-contrast-rgb: 0,0,0;
//    --ion-color-tertiary-shade: #dc9235;
//    --ion-color-tertiary-tint: #fbaf50;
//
//    --ion-color-success: #2dd36f;
//    --ion-color-success-rgb: 45,211,111;
//    --ion-color-success-contrast: #ffffff;
//    --ion-color-success-contrast-rgb: 255,255,255;
//    --ion-color-success-shade: #28ba62;
//    --ion-color-success-tint: #42d77d;
//
//    --ion-color-warning: #ffc409;
//    --ion-color-warning-rgb: 255,196,9;
//    --ion-color-warning-contrast: #000000;
//    --ion-color-warning-contrast-rgb: 0,0,0;
//    --ion-color-warning-shade: #e0ac08;
//    --ion-color-warning-tint: #ffca22;
//
//    --ion-color-danger: #eb445a;
//    --ion-color-danger-rgb: 235,68,90;
//    --ion-color-danger-contrast: #ffffff;
//    --ion-color-danger-contrast-rgb: 255,255,255;
//    --ion-color-danger-shade: #cf3c4f;
//    --ion-color-danger-tint: #ed576b;
//
//    --ion-color-dark: #002451;
//    --ion-color-dark-rgb: 0,36,81;
//    --ion-color-dark-contrast: #ffffff;
//    --ion-color-dark-contrast-rgb: 255,255,255;
//    --ion-color-dark-shade: #002047;
//    --ion-color-dark-tint: #1a3a62;
//
//    --ion-color-medium: #f4dfc5;
//    --ion-color-medium-rgb: 244,223,197;
//    --ion-color-medium-contrast: #000000;
//    --ion-color-medium-contrast-rgb: 0,0,0;
//    --ion-color-medium-shade: #d7c4ad;
//    --ion-color-medium-tint: #f5e2cb;
//
//    --ion-color-light: #f2eee8;
//    --ion-color-light-rgb: 242,238,232;
//    --ion-color-light-contrast: #000000;
//    --ion-color-light-contrast-rgb: 0,0,0;
//    --ion-color-light-shade: #d5d1cc;
//    --ion-color-light-tint: #f3f0ea;
//  }
//
//  /*
//   * iOS Dark Theme
//   * -------------------------------------------
//   */
//
//  .ios body {
//    --ion-background-color: #000000;
//    --ion-background-color-rgb: 0,0,0;
//
//    --ion-text-color: #ffffff;
//    --ion-text-color-rgb: 255,255,255;
//
//    --ion-color-step-50: #0d0d0d;
//    --ion-color-step-100: #1a1a1a;
//    --ion-color-step-150: #262626;
//    --ion-color-step-200: #333333;
//    --ion-color-step-250: #404040;
//    --ion-color-step-300: #4d4d4d;
//    --ion-color-step-350: #595959;
//    --ion-color-step-400: #666666;
//    --ion-color-step-450: #737373;
//    --ion-color-step-500: #808080;
//    --ion-color-step-550: #8c8c8c;
//    --ion-color-step-600: #999999;
//    --ion-color-step-650: #a6a6a6;
//    --ion-color-step-700: #b3b3b3;
//    --ion-color-step-750: #bfbfbf;
//    --ion-color-step-800: #cccccc;
//    --ion-color-step-850: #d9d9d9;
//    --ion-color-step-900: #e6e6e6;
//    --ion-color-step-950: #f2f2f2;
//
//    --ion-item-background: #000000;
//
//    --ion-card-background: #1c1c1d;
//  }
//
//  .ios ion-modal {
//    --ion-background-color: var(--ion-color-step-100);
//    --ion-toolbar-background: var(--ion-color-step-150);
//    --ion-toolbar-border-color: var(--ion-color-step-250);
//  }
//
//
//  /*
//   * Material Design Dark Theme
//   * -------------------------------------------
//   */
//
//  .md body {
//    --ion-background-color: #121212;
//    --ion-background-color-rgb: 18,18,18;
//
//    --ion-text-color: #ffffff;
//    --ion-text-color-rgb: 255,255,255;
//
//    --ion-border-color: #222222;
//
//    --ion-color-step-50: #1e1e1e;
//    --ion-color-step-100: #2a2a2a;
//    --ion-color-step-150: #363636;
//    --ion-color-step-200: #414141;
//    --ion-color-step-250: #4d4d4d;
//    --ion-color-step-300: #595959;
//    --ion-color-step-350: #656565;
//    --ion-color-step-400: #717171;
//    --ion-color-step-450: #7d7d7d;
//    --ion-color-step-500: #898989;
//    --ion-color-step-550: #949494;
//    --ion-color-step-600: #a0a0a0;
//    --ion-color-step-650: #acacac;
//    --ion-color-step-700: #b8b8b8;
//    --ion-color-step-750: #c4c4c4;
//    --ion-color-step-800: #d0d0d0;
//    --ion-color-step-850: #dbdbdb;
//    --ion-color-step-900: #e7e7e7;
//    --ion-color-step-950: #f3f3f3;
//
//    --ion-item-background: #1e1e1e;
//
//    --ion-toolbar-background: #1f1f1f;
//
//    --ion-tab-bar-background: #1f1f1f;
//
//    --ion-card-background: #1e1e1e;
//  }
//}
